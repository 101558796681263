import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../assets/tmc.jpg';

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleTakeActionClick = () => {
    window.open('https://linktr.ee/tmctampa', '_blank', 'noopener,noreferrer');
  };

  return (
    <header>
      <div className="logo">
        <img src={logo} alt="TMC Logo" />
      </div>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li className="dropdown" onMouseEnter={toggleDropdown} onMouseLeave={toggleDropdown}>
            <Link to="/about">About</Link>
            {dropdownOpen && (
              <ul className="dropdown-menu">
                <li><Link to="/about/who-are-we">Who Are We</Link></li>
                <li><Link to="/about/mission">Mission</Link></li>
                <li><Link to="/about/our-story">Our Story</Link></li>
              </ul>
            )}
          </li>
          <li><Link to="/blog">Blog</Link></li>
          <li><Link to="/contact">Contact</Link></li>
          <li><Link to="/dawah">Da'wah</Link></li>
          <li><Link to="/events">Events</Link></li>
          <li><Link to="/jummah-time">Jummah Time</Link></li>
          <li><Link to="/programs">Programs</Link></li>
          <li><Link to="/ramadan">Ramadan</Link></li>
          <li><Link to="/umrah-trip">TMC Umrah Trip</Link></li>
          <li><Link to="/testimonials">Testimonials</Link></li>
        </ul>
      </nav>
      <div className="take-action">
        <button onClick={handleTakeActionClick}>Take Action</button>
      </div>
    </header>
  );
};

export default Header;

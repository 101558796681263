import React, { useState, useEffect } from 'react';
import './JummahTime.css';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

const JummahTime = () => {
  const [khutbahs, setKhutbahs] = useState([]);

  useEffect(() => {
    const fetchKhutbahs = async () => {
      const docRef = doc(db, 'Jummuah', 'khutbahs');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setKhutbahs(docSnap.data().masjids);
      } else {
        console.log('No such document!');
      }
    };

    fetchKhutbahs();
  }, []);

  return (
    <div className="jummah-container">
      <h1>Jummah Khutbahs</h1>
      {khutbahs.map((masjid, index) => (
        <div key={index} className="masjid">
          <div className="masjid-name">
            <h2>{masjid.name}</h2>
            <p>{masjid.address[0]}</p>
            <p>{masjid.address[1]}</p>
          </div>
          <div className="khutbah-details">
            <div className="khateebs">
              {masjid.khateebs.map((khateeb, idx) => (
                <p key={idx}>{khateeb}</p>
              ))}
            </div>
            <div className="times">
              {masjid.times.map((time, idx) => (
                <p key={idx}>{time}</p>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default JummahTime;

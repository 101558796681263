import React from 'react';
import './Ramadan.css';

const Ramadan = () => {
  return (
    <div className="ramadan-container">
      <div className="text-group">
        <p className="arabic-text">شَهْرُ رَمَضَانَ الَّذِي أُنزِلَ فِيهِ الْقُرْآنُ هُدًى لِّلنَّاسِ وَبَيِّنَاتٍ مِّنَ الْهُدَىٰ وَالْفُرْقَانِ</p>
        <p className="english-text">The month of Ramadan in which was revealed the Qur’an, a guidance for the people and clear proofs of guidance and criterion.</p>
        <p className='english-text'>Of the seven who will be under the shade of the throne of Allah on the day of judgement is “a person whose heart is attached to the masjid” [Al Bukhari and Muslim]</p>
        <p className='english-text'>Come join as we break our fast, pray salaah, come closer to Allah, and attach our hearts to the masjid every evening in the month of Ramadhan at the TMC masjid, N56th street.</p>
      </div>
      <div className="video-container">
        <video
          src="https://firebasestorage.googleapis.com/v0/b/themc-d23c7.appspot.com/o/ramadan%2FEE4BDD10FA38E2729C82840A55804A90_video_dashinit.mp4?alt=media&token=80e0a294-f09f-433a-9eb7-1d9ee7b464a3"
          width="350"
          height="500"
          controls
          autoPlay
          loop
          unmuted
        />
      </div>
    </div>
  );
};

export default Ramadan;

import React from 'react';

const Mission = () => {
  return (
    <div>
      <h3>Mission</h3>
      <p>
        To create a safe space for everyone through educational services and mentorship programs.
      </p>
    </div>
  );
};

export default Mission;

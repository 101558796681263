import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Dawah from './pages/Dawah';
import Events from './pages/Events';
import JummahTime from './pages/JummahTime';
import Programs from './pages/Programs';
import Ramadan from './pages/Ramadan';
import UmrahTrip from './pages/UmrahTrip';
import Testimonials from './pages/Testimonials';
import WhoAreWe from './pages/WhoAreWe';
import Mission from './pages/Mission';
import OurStory from './pages/OurStory';

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />}>
              <Route path="who-are-we" element={<WhoAreWe />} />
              <Route path="mission" element={<Mission />} />
              <Route path="our-story" element={<OurStory />} />
            </Route>
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/dawah" element={<Dawah />} />
            <Route path="/events" element={<Events />} />
            <Route path="/jummah-time" element={<JummahTime />} />
            <Route path="/programs" element={<Programs />} />
            <Route path="/ramadan" element={<Ramadan />} />
            <Route path="/umrah-trip" element={<UmrahTrip />} />
            <Route path="/testimonials" element={<Testimonials />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
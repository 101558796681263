import React from 'react';

const WhoAreWe = () => {
  return (
    <div>
      <h3>Who Are We</h3>
      <p>
        The Muslim Connection is an Islamic organization that provides an outlet for social and spiritual needs of the Tampa Bay Muslim community through venue events, workshops, activities, and Halaqas. We strive to spread the love of Allah through the dissemination of Islam (knowledge, wisdom, purity, and spiritual richness).
      </p>
    </div>
  );
};

export default WhoAreWe;

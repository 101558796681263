import React from 'react';

const OurStory = () => {
  return (
    <div>
      <h3>Our Story</h3>
      <p>
        TMC was founded by Br.Hassan Sultan. Since 2015, TMC has brought tremendous positive experiences to the community through our wide range of programs, activities, workshops, and seminars.
      </p>
    </div>
  );
};

export default OurStory;

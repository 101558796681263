import React from 'react';
import './Blog.css';

const Blog = () => {
  return (
    <div className="blog-container">
      <h1>Our Blog</h1>
      <div className="blog-posts">
        <div className="blog-post">
          <h2>Coming Soon: TMC makes comeback on the cyber space</h2>
          <p>Stay tuned! Our first blog post will be available soon.</p>
        </div>
        
        {/* Add more placeholders as needed */}
      </div>
    </div>
  );
};

export default Blog;

import React, { useState, useEffect } from 'react';
import './Events.css';
import { db } from "../firebaseConfig"; // Adjust the import path as needed
import { collection, query, where, onSnapshot, doc, updateDoc, increment, arrayUnion, arrayRemove, Timestamp, setDoc } from 'firebase/firestore';
import dayjs from 'dayjs';

const Events = () => {
  const [date, setDate] = useState(dayjs());  // Initialize to today's date
  const [events, setEvents] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showAllEvents, setShowAllEvents] = useState(false);

  // Helper function to get the start and end of the day
  const getStartAndEndOfDay = (date) => {
    const start = dayjs(date).startOf('day').toDate();
    const end = dayjs(date).endOf('day').toDate();
    return { start: Timestamp.fromDate(start), end: Timestamp.fromDate(end) };
  };

  useEffect(() => {
    const loadUserId = async () => {
      const storedUserId = localStorage.getItem('userId');
      if (storedUserId) {
        setUserId(storedUserId);
        console.log("Loaded User ID from localStorage:", storedUserId);
      } else {
        // Generate a new user ID
        const newUserId = Math.random().toString(36).substring(2) + Date.now().toString(36);
        await setDoc(doc(db, 'users', newUserId), { uid: newUserId });
        localStorage.setItem('userId', newUserId);
        setUserId(newUserId);
        console.log("Generated and Stored User ID:", newUserId);
      }
    };

    loadUserId();
  }, []);

  useEffect(() => {
    const fetchEvents = () => {
      const eventsRef = query(collection(db, 'events'), where('date', '>=', Timestamp.now()));
      const unsubscribe = onSnapshot(eventsRef, (snapshot) => {
        const fetchedEvents = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          date: doc.data().date.toDate() // Convert Timestamp to Date
        }));
        console.log("Fetched events:", fetchedEvents);
        setEvents(fetchedEvents);
      });

      return () => unsubscribe();  // Detach listener when component unmounts
    };

    fetchEvents();
  }, []);

  const handleDateChange = (e) => {
    setDate(dayjs(e.target.value));
    const { start, end } = getStartAndEndOfDay(dayjs(e.target.value));
    const eventsRef = query(
      collection(db, 'events'),
      where('date', '>=', start),
      where('date', '<=', end)
    );
    const unsubscribe = onSnapshot(eventsRef, (snapshot) => {
      const fetchedEvents = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date.toDate() // Convert Timestamp to Date
      }));
      console.log("Fetched events:", fetchedEvents);
      setEvents(fetchedEvents);
    });

    return () => unsubscribe();  // Detach listener when component unmounts
  };

  const handleRSVP = async (event) => {
    if (!userId) {
      console.error("User ID not available");
      return;
    }

    const eventRef = doc(db, 'events', event.id);
    try {
      if (event.rsvpUsers && event.rsvpUsers.includes(userId)) {
        // User has already RSVPed, so we cancel the RSVP
        await updateDoc(eventRef, {
          rsvpUsers: arrayRemove(userId),
          interestedCount: increment(-1)
        });
        console.log("RSVP cancelled successfully!");
        // Update local state to reflect the RSVP decrement
        setEvents(events.map(e => e.id === event.id ? { ...e, interestedCount: e.interestedCount - 1, rsvpUsers: e.rsvpUsers.filter(uid => uid !== userId) } : e));
      } else {
        // User has not RSVPed, so we add their RSVP
        await updateDoc(eventRef, {
          rsvpUsers: arrayUnion(userId),
          interestedCount: increment(1)
        });
        console.log("RSVP added successfully!");
        // Update local state to reflect the RSVP increment
        setEvents(events.map(e => e.id === event.id ? { ...e, interestedCount: e.interestedCount + 1, rsvpUsers: [...(e.rsvpUsers || []), userId] } : e));
      }
    } catch (error) {
      console.error("Error updating RSVP:", error);
    }
  };

  const renderItems = () => {
    const displayedEvents = showAllEvents ? events : events.slice(0, 5);
    return displayedEvents.map(item => {
      const isRSVPed = item.rsvpUsers && item.rsvpUsers.includes(userId);
      return (
        <div key={item.id} className="event-item">
          <div className="event-details" onClick={() => {
            setSelectedEvent(item);
            setModalVisible(true);
          }}>
            <h3>{item.title}</h3>
            <p>{item.description}</p>
            <p>{dayjs(item.date).format('MMMM D, YYYY')}</p> {/* Display the date */}
          </div>
          <button onClick={() => handleRSVP(item)}>
            {isRSVPed ? "Cancel RSVP" : "RSVP"}
          </button>
        </div>
      );
    });
  };

  return (
    <div className="events-container">
      <h1>Events</h1>
      <input type="date" value={date.format('YYYY-MM-DD')} onChange={handleDateChange} className="date-picker" />
      <div className="events-list">
        {renderItems()}
      </div>
      {events.length > 5 && (
        <button className="view-more-button" onClick={() => setShowAllEvents(!showAllEvents)}>
          {showAllEvents ? "View Less" : "View More"}
        </button>
      )}
      {modalVisible && selectedEvent && (
        <div className="modal-overlay" onClick={() => setModalVisible(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{selectedEvent.title}</h2>
            {selectedEvent.imageURL && (
              <img src={selectedEvent.imageURL} alt={selectedEvent.title} className="event-image" />
            )}
            <p>{selectedEvent.description}</p>
            <p>{dayjs(selectedEvent.date).format('MMMM D, YYYY')}</p> {/* Display the date */}
            <button onClick={() => setModalVisible(false)}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Events;

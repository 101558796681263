import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Adjust the import path as needed
import { collection, getDocs } from 'firebase/firestore';
import './Testimonials.css';

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState([]);

  useEffect(() => {
    const fetchTestimonials = async () => {
      const querySnapshot = await getDocs(collection(db, 'testimonials'));
      const fetchedTestimonials = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTestimonials(fetchedTestimonials);
    };

    fetchTestimonials();
  }, []);

  return (
    <div className="testimonials-container">
      <title>Testimonials</title>
      <h1>Testimonials</h1>
      <div className="testimonials-list">
        {testimonials.length === 0 ? (
          <h2>Coming soon!</h2>
        ) : (
          testimonials.map(testimonial => (
            <div key={testimonial.id} className="testimonial-item">
              <p className="testimonial-body">{testimonial.body}</p>
              <p className="testimonial-name">- {testimonial.name}</p>
            </div>
          ))
        )}
      </div>
      <Link to="/">Go back to the homepage</Link>
    </div>
  );
};

export default Testimonials;

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Adjust the import path as needed
import { collection, getDocs } from 'firebase/firestore';
import './UmrahTrip.css'; // Create this CSS file for styles

const UmrahTrip = () => {
  const [umrahContents, setUmrahContents] = useState([]);

  useEffect(() => {
    const fetchUmrahContents = async () => {
      const querySnapshot = await getDocs(collection(db, 'umrahContent'));
      const fetchedUmrahContents = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setUmrahContents(fetchedUmrahContents);
    };

    fetchUmrahContents();
  }, []);

  return (
    <div className="umrah-container">
      <title>Umrah Trip</title>
      {umrahContents.length === 0 ? (
        <h2>Coming soon!</h2>
      ) : (
        umrahContents.map(content => (
          <div key={content.id} className="umrah-section">
            <h2 className='home-title'>{content.heading}</h2>
            <div className="pictures-grid">
              {content.pictures.map((url, index) => (
                <img key={index} src={url} alt={`Umrah ${content.heading}`} className="umrah-picture" />
              ))}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default UmrahTrip;

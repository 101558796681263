import React, { useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';
import emailjs from 'emailjs-com';

const containerStyle = {
  width: '100%',
  height: '300px',
};

const center = {
  lat: 28.0222084,
  lng: -82.3939793,
};

// Define custom map styles
const mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ffffff"  // Set text color to white for better visibility
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#ffffff"  // Set text color to white for better visibility
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
];

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');

  const handleAddressPress = () => {
    window.open(
      'https://www.google.com/maps/place/The+Muslim+Connection+-TMC/@28.0222318,-82.3941348,21z/data=!4m6!3m5!1s0x88c2c623d6412c1f:0xb59da8d5b96aba4a!8m2!3d28.0222084!4d-82.3939793!16s%2Fg%2F11g8kvzmx5?entry=ttu',
      '_blank'
    );
  };

  const handlePhonePress = () => {
    window.open('tel:+18133101807');
  };

  const handleEmailPress = () => {
    window.open('mailto:themuslimconnection@gmail.com');
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    emailjs.send('service_ghka4rg', 'template_4c7zs9r', {
      from_name: `${firstName} ${lastName}`,
      from_email: emailAddress,
      message: `First Name: ${firstName}\nLast Name: ${lastName}\nEmail: ${emailAddress}`
    }, 'p1wIbtRMxnk6rWf13')
    .then((response) => {
       console.log('SUCCESS!', response.status, response.text);
       alert('Form Submitted: Your information has been sent.');
    }, (error) => {
       console.log('FAILED...', error);
       alert('Failed to send email. Please try again later.');
    });

    setFirstName('');
    setLastName('');
    setEmailAddress('');
  };

  const handleSocialPress = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="contact-container">
      <div className="map">
        <LoadScript googleMapsApiKey="AIzaSyBV974T5SQlJrFKWcx0SzjkOsyek1l3kho">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={20}
            options={{ styles: mapStyles }}
          >
            <Marker position={center} />
          </GoogleMap>
        </LoadScript>
      </div>
      <div className="contact-info">
        <h1>Contact Us</h1>
        <p onClick={handleAddressPress} className="info-text">
          Address: <span>8080 North 56th Street Tampa Florida</span>
        </p>
        <p onClick={handlePhonePress} className="info-text">
          Phone: <span>+1 813 310 1807</span>
        </p>
        <p onClick={handleEmailPress} className="info-text">
          Email: <span>themuslimconnection@gmail.com</span>
        </p>
        <h2>Please fill this form out to join the mailing list!</h2>
        <form onSubmit={handleFormSubmit} className="form">
          <input
            type="text"
            placeholder="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
          <input
            type="email"
            placeholder="Email Address"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            required
          />
          <button type="submit" className="submit-button">Submit</button>
        </form>
        <h2>Follow Us</h2>
        <div className="social-links">
          <div
            onClick={() => handleSocialPress('https://www.instagram.com/themuslimconnection/?hl=en')}
            className="social-link"
          >
            <FontAwesomeIcon icon={faInstagram} style={{color: "#FFD43B"}} size="2x" />
            <p>Instagram</p>
          </div>
          <div
            onClick={() => handleSocialPress('https://www.facebook.com/themuslimconnection/')}
            className="social-link"
          >
            <FontAwesomeIcon icon={faFacebook} style={{color: "#3b5998"}} size="2x" />
            <p>Facebook</p>
          </div>
          <div
            onClick={() => handleSocialPress('https://www.youtube.com/@MuslimConnection')}
            className="social-link"
          >
            <FontAwesomeIcon icon={faYoutube} style={{color: "#FF0000"}} size="2x" />
            <p>YouTube</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

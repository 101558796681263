import React from 'react';
import './Dawah.css';

const Dawah = () => {
  return (
    <div className="dawah-container">
      <h1>TMC DA’WAH PROJECT</h1>
      <p className="intro-text">
        It is our duty to spread the message of Islam, according to the authentic sources of Quran and Sunnah and the Ijmaa of the Sahabah in order to introduce people to the values of God (Allah)
      </p>

      <section className="dawah-section">
        <h2>WHAT IS DA’WAH?</h2>
        <p className="dawah-definition">
          DA’WAH MEANS <em>INVITING</em> PEOPLE TO ISLAM.
          <br />
          Do so with respect, courtesy and kindness.
        </p>
        <blockquote>
          “Invite to the path of your Lord with wisdom and good advice” (An-Nahl 16:125).
        </blockquote>
      </section>

      <section className="dawah-info">
        <p>
          Da'wah provides a unique way to open communication channels with others in order to inform them about Islam. Through Da'wah, we can remove lots of misunderstandings, misrepresentations about this beautiful religion, and spread the messages of Islam.
        </p>
        <p>
          Da'wah is the individual responsibility of every Muslim man and woman. Allah commanded the believers to do whatever they can in order to spread Allah's message.
        </p>
        <blockquote>
          “Let there arise out of you a group of people inviting to all that is good (Islam), enjoining Al-Ma'roof (whatever is good) and forbidding Al-Munkar (whatever is evil). And it is they who are successful." (Al-Imran 3:104).
        </blockquote>
      </section>

      <div className="actions">
        <p>Visit tmcdawah click <a href="https://www.instagram.com/themuslimconnection/?hl=en" target="_blank" rel="noopener noreferrer" className="highlight-link">HERE</a></p>
        <button onClick={() => window.open('https://tinyurl.com/TMC-Dawah', '_blank')} className="donate-button">
          DONATE TO DA'WAH PROJECT
        </button>
      </div>

      <div className="video-container">
        <video
          src="https://firebasestorage.googleapis.com/v0/b/themc-d23c7.appspot.com/o/dawah%2F2F436A9606BEBD540B2A6858A3E7A08D_video_dashinit.mp4?alt=media&token=4ff6b40c-c36c-4033-bf5c-1ff1e83ec390"
          width="350"
          height="500"
          controls
          autoPlay
          loop
          unmuted
        />
    </div>
    </div>
  );
};

export default Dawah;

import React, { useState, useEffect } from 'react';
import './Programs.css';
import { db } from '../firebaseConfig'; // Adjust the import path as needed
import { collection, getDocs } from 'firebase/firestore';

const Programs = () => {
  const [programs, setPrograms] = useState([]);

  useEffect(() => {
    const fetchPrograms = async () => {
      const querySnapshot = await getDocs(collection(db, 'programs'));
      const fetchedPrograms = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Ensure the date field is a valid Date object for sorting
      fetchedPrograms.forEach(program => {
        if (program.date) {
          program.date = new Date(program.date);
        }
      });

      // Sort programs by date, closest to furthest
      fetchedPrograms.sort((a, b) => a.date - b.date);

      setPrograms(fetchedPrograms);
    };

    fetchPrograms();
  }, []);

  return (
    <div className="programs-container">
      <h1>Programs</h1>
      <div className="programs-list">
        {programs.map((program) => (
          <div key={program.id} className="program-item">
            <img src={program.imageURL} alt={program.name} className="program-image" />
            <h2>{program.name}</h2>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Programs;

import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import logo from '../assets/tmc.jpg';
import FlipWords from '../components/FlipWords';

const Home = () => {
  const words = ['Faith', 'Community', 'Knowledge', 'Love', 'Unity', 'Peace'];

  return (
    <div className="home-container">
      <div className="home-content">
        <h1 className="home-title">The Muslim Connection</h1>
        <p className="home-description">
          Connecting Muslims through <FlipWords words={words} duration={800} />
        </p>
        <div className="home-buttons">
          <Link to="/about">
            <button className="home-button learn-more">Learn More</button>
          </Link>
          <Link to="/contact">
            <button className="home-button get-involved">Get Involved</button>
          </Link>
        </div>
      </div>
      <div className="home-image">
        <img src={logo} alt="TMC Logo" />
      </div>
    </div>
  );
};

export default Home;

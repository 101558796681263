import React from 'react';
import { Link, Outlet, Navigate, useLocation } from 'react-router-dom';
import './About.css';

const About = () => {
  const location = useLocation();

  // Check if the current path is exactly '/about' and redirect to 'who-are-we'
  if (location.pathname === '/about') {
    return <Navigate to="who-are-we" />;
  }

  return (
    <div className="about-container">
      <div className="about-sidebar">
        <h2>About</h2>
        <ul>
          <li><Link to="who-are-we">Who Are We</Link></li>
          <li><Link to="mission">Mission</Link></li>
          <li><Link to="our-story">Our Story</Link></li>
        </ul>
      </div>
      <div className="about-content">
        <Outlet />
      </div>
    </div>
  );
};

export default About;

// Import the functions you need from the SDKs you need
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {
  initializeAuth,
  getAuth,
  getReactNativePersistence,
} from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBoZNiIJAbZD7ZDON13iJ-7ktNe0OaYkJA",
  authDomain: "themc-d23c7.firebaseapp.com",
  projectId: "themc-d23c7",
  storageBucket: "themc-d23c7.appspot.com",
  messagingSenderId: "613348136354",
  appId: "1:613348136354:web:6dbf02619c40bfd9f58efb",
};

let app = null;
// Initialize Firebase
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
}

const db = getFirestore(app);

// Log the app instance to ensure it's initialized
// console.log('Firebase app initialized:', app);
// console.log('Firestore instance:', db);

export { db };
// export default auth;
